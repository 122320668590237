#main-nav .active {
  color: #13b690;
}

#service-menu .active {
  color: #13b690;
}

.menu-link {
  color: white;
  text-decoration: none;
}

.menu-link:hover {
  color: #ff5722; /* Change color on hover */
}

/* Default background image */

