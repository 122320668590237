@import url("https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@400;500;600;700&display=swap");

* {
  font-family: "Bai Jamjuree", sans-serif;
}

.font-family {
  font-family: "Bai Jamjuree", sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.animated {
  animation: slideInLeft 1s forwards;
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 100px;
}

body::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 100px;
  height: 10px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  background-color: transparent;
}

#contact input:-webkit-autofill,
#contact input:-webkit-autofill:hover, 
#contact input:-webkit-autofill:focus,
#contact textarea:-webkit-autofill,
#contact textarea:-webkit-autofill:hover,
#contact textarea:-webkit-autofill:focus,
#contact select:-webkit-autofill,
#contact select:-webkit-autofill:hover,
#contact select:-webkit-autofill:focus {
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  background-color: transparent;
}


.sidebar {
  /* Styling for the sticky sidebar */
  position: sticky;
  top: 0;
  /* Add other styles as needed */
}

.content {
  /* Styling for the scrollable content area */
  /* Adjust height, overflow, and other styles as needed */
  overflow-y: auto;
  height: 100vh; /* Adjust height as needed */
}

.scrollbar-hide {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.hero-banner-height {
  min-height: calc(100dvh - 92.23px);
}

.uiux-designservice {
  background-image: linear-gradient(to top right, #1b2821 0%, #101010 40%);
}

.section-background-2 {
  background-image: linear-gradient(to right, #1b2821 0%, #101010 40%);
}
.section-background-3 {
  background-image: linear-gradient(to bottom, #1b2821 0%, #0b0b0b 40%);
}

.uiuxacordian-background {
  background: rgb(16, 27, 24);
  background: linear-gradient(to bottom left, #141b11 0%, #0b0b0b 40%);
}

.service-background {
  background: linear-gradient(90deg, #030303 100%, rgba(0, 0, 0, 0.03) 100%);
  background-size: cover;
  background-repeat: no-repeat;
}

.service-section-background {
  background: rgb(16, 27, 24);
  background: linear-gradient(
    28deg,
    rgba(16, 27, 24, 1) 0%,
    rgba(11, 11, 11, 1) 57%
  );
  /* background: rgb(14, 33, 160);
  background: radial-gradient(
    circle,
    rgba(14, 33, 160, 1) 0%,
    rgba(4, 13, 18, 1) 100%
  ); */
  /* background: rgb(11, 11, 11);
  background: linear-gradient(
    180deg,
    rgba(11, 11, 11, 1) 28%,
    rgba(0, 197, 187, 1) 100%
  ); */
}

/* .service-section-background{
  background-color: #7ed6df;
background-image: linear-gradient(315deg, #7ed6df 0%, #000000 84%);
} */

.service-section-card {
  background-color: #1e453e;
  background-image: linear-gradient(314deg, #1e453e 0%, #182c25 74%);
}

.service-section-background2 {
  background: rgb(16, 27, 24);
  background: linear-gradient(to top left, #141b11 0%, #0b0b0b 40%);
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.border-gradient {
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #ff0000, #0000ff) !important;
  border-radius: 0 100px 100px 0;
}

.poup-shadow {
  box-shadow: -4px -1px 40px -6px rgba(255, 255, 255, 0.36);
  -webkit-box-shadow: -4px -1px 40px -6px rgba(255, 255, 255, 0.36);
  -moz-box-shadow: -4px -1px 40px -6px rgba(255, 255, 255, 0.36);
}

.poup-animatin {
  animation: sclaeUp 0.4s;
}

.circle {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  background-color: #333;
  border-radius: 50%;
  transition: width 0.5s ease, height 0.5s ease;
  clip-path: circle(6% at 100% 0);
}

.open .circle {
  width: 100vw;
  height: 100vh;
}

@keyframes sclaeUp {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes imageFade {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 0.5;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@layer components {
  .outlined-btn {
    @apply text-white text-xl bg-transparent px-10 py-3 border border-white rounded-full font-medium hover:text-info hover:border-info transition duration-200;
  }

  .footerTextHoverEffect {
    @apply hover:text-info transition-all duration-200;
  }

  .callingBtn {
    @apply px-10 py-4 font-bold text-xl rounded-full;
  }

  .gradientText {
    @apply font-black bg-gradient-to-r from-[#FFDE00] from-0% to-[#14C99E] to-100% inline-block text-transparent bg-clip-text;
  }
  .service-title-font-size {
    @apply md:text-5xl text-3xl xl:text-[72px] leading-[45px] md:leading-[50px] xl:leading-[80px];
  }
  .section-padding-services-page {
    @apply py-8  lg:py-[120px];
  }
  .service-title-box-padding {
    @apply pb-8 md:pb-12 lg:pb-16 xl:pb-20;
  }
  .team-page-title {
    @apply md:leading-[80px] sm:leading-[60px]  gradientText text-[28px] sm:text-4xl xl:text-7xl service-title-box-padding;
  }
}
/* styles.css or your global stylesheet */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-fadeIn {
  animation: fadeIn 1s ease-in-out;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 1440px) {
  .working-proccess-backgrund-line {
    background-image: url("./assets/Photo/work-process-line.png");
  }
}

/* For Chrome and Safari */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px transparent inset !important; /* Adjust the shadow color as needed */
  box-shadow: 0 0 0 30px transparent inset !important; /* Adjust the shadow color as needed */
  -webkit-background: transparent !important;
}

/* For Firefox */
input:-moz-autofill {
  -webkit-box-shadow: 0 0 0 30px transparent inset !important; /* Adjust the shadow color as needed */
  box-shadow: 0 0 0 30px transparent inset !important; /* Adjust the shadow color as needed */
}


.transition-opacity {
  transition: opacity 0.5s ease;
}

.transition-transform {
  transition: transform 0.5s ease;
}
